<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">RESULT </span>SLIP
    </PageHeader>

    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1">
              Search Student by Index Number
            </v-toolbar-title>

            <v-autocomplete
              @change="getstudentResult"
              :loading="loadStudent"
              v-model="index_number"
              :items="studentcampusBasket"
              :search-input.sync="searchStudent"
              @keyup="debouncesearchStudent"
              cache-items
              hide-details
              flat
              item-text="index_number_title_name"
              item-value="id"
              label="Index Number"
              solo-inverted
              class="mx-4"
              :rules="[(v) => !!v || 'Index number is required...']"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <v-img
                      :src="data.item.avatar_url"
                      :lazy-src="data.item.avatar_url"
                    >
                      <template #placeholder>
                        <v-row
                          class="ma-0 fill-height"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.index_number_title_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search
                    <strong>Student...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="pa-4 grow fill-height">
          <div
            v-if="!response && levelcourseBasket.length <= 0"
            class="font-weight-bold"
          >
            Search Student Result Slip
          </div>
          <div
            v-else-if="response && levelcourseBasket.length <= 0"
            class="font-weight-bold"
          >
            No Results Found Yet...
          </div>
          <div v-else>
            <v-row align="center" justify="center">
              <v-toolbar color="primary" class="secondary--text" elevation="1">
                <v-toolbar-title class="font-weight-bold">
                  RESULT SLIP
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-switch
                  class="mt-6"
                  color="secondary"
                  value="secondary"
                  v-model="showClassExamScore"
                  inset
                ></v-switch>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" dark icon color="secondary">
                      <v-icon large>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Convert To PDF And Print</span>
                </v-tooltip>
              </v-toolbar>
            </v-row>
            <v-row>
              <v-col>
                <template v-for="(item, index) in levelcourseBasket">
                  <div :key="index">
                    <div class="headline text-center">{{ item.key }}</div>
                    <template v-for="(semester, level) in item.acc">
                      <div class="mb-5" :key="level">
                        <div class="title mt-2">{{ level }}</div>
                        <v-simple-table dense style="width: 100%">
                          <thead>
                            <tr>
                              <th style="width: 10%" class="text-left">
                                COURSE CODE
                              </th>
                              <th
                                :colspan="
                                  semester[0].grade_point !== null ? 2 : 3
                                "
                                class="text-left"
                              >
                                COURSE NAME
                              </th>
                              <th style="width: 10%" class="text-center">
                                CREDIT HOURS
                              </th>
                              <th
                                style="width: 10%"
                                class="text-center"
                                v-if="showClassExamScore"
                              >
                                CLASS SCORE
                              </th>
                              <th
                                style="width: 10%"
                                class="text-center"
                                v-if="showClassExamScore"
                              >
                                EXAM SCORE
                              </th>
                              <th style="width: 10%" class="text-center">
                                TOTAL SCORE
                              </th>
                              <th style="width: 10%" class="text-center">
                                GRADE
                              </th>
                              <th
                                class="text-center"
                                v-show="semester[0].grade_point !== null"
                                style="width: 10%"
                              >
                                GRADE POINT
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(semester, semester_index) in semester"
                              :key="semester_index"
                            >
                              <td style="width: 10%" class="text-left">
                                {{ semester.course_code }}
                              </td>
                              <td
                                :colspan="semester.grade_point !== null ? 2 : 3"
                              >
                                {{ semester.course_name }}
                                <!-- <span class="red--text" v-if="semester.resit">
                                  *
                                </span> -->
                              </td>

                              <td class="text-center" style="width: 10%">
                                {{ semester.credit_hours }}
                              </td>
                              <td
                                colspan="5"
                                class="text-center red--text font-weight-bold"
                                v-if="!semester.is_published"
                              >
                                Not Published
                              </td>
                              <template v-else>
                                <td
                                  class="text-center"
                                  v-if="showClassExamScore"
                                  style="width: 10%"
                                >
                                  {{ semester.mid_sem_score }}
                                </td>
                                <td
                                  class="text-center"
                                  v-if="showClassExamScore"
                                  style="width: 10%"
                                >
                                  {{ semester.end_of_sem_score }}
                                </td>
                                <td class="text-center" style="width: 10%">
                                  {{ semester.total_score }}
                                </td>
                                <td class="text-center" style="width: 10%">
                                  {{ semester.grade }}
                                </td>
                                <td
                                  class="text-center"
                                  v-if="semester.grade_point !== null"
                                  style="width: 10%"
                                >
                                  {{
                                    parseFloat(
                                      semester.grade_point *
                                        semester.credit_hours
                                    ).toFixed(2)
                                  }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 9 : 7"></td>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 9 : 7"></td>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 7 : 5"></td>
                              <td
                                class="text-center font-weight-bold caption text-uppercase size"
                                style="width: 10%"
                              >
                                Semester
                              </td>
                              <td
                                class="text-center font-weight-bold caption text-uppercase size"
                                style="width: 10%"
                              >
                                Cumulative
                              </td>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 6 : 4"></td>
                              <td
                                class="text-right font-weight-bold caption text-uppercase size"
                              >
                                Credit Hours
                              </td>
                              <td color="primary" class="text-center">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ e.credit }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>

                              <td color="primary" class="text-center">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ e.cumulative_credit }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 6 : 4"></td>
                              <td
                                class="text-right font-weight-bold caption text-uppercase"
                                style="width: 10%"
                              >
                                Credit Obtained
                              </td>
                              <td class="text-center" style="width: 10%">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ e.credit_obtained }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                              <td class="text-center" style="width: 10%">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ e.cumulative_credit_obtained }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 6 : 4"></td>
                              <td
                                class="text-right font-weight-bold caption text-uppercase"
                                style="width: 10%"
                              >
                                Weighted Marks
                              </td>
                              <td class="text-center" style="width: 10%">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ e.total }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                              <td class="text-center" style="width: 10%">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ e.cumulative_total }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td :colspan="showClassExamScore ? 6 : 4"></td>
                              <td
                                class="text-right font-weight-bold caption text-uppercase"
                                style="width: 10%"
                              >
                                Weighted Average
                              </td>
                              <td class="text-center" style="width: 10%">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{ parseFloat(e.weighted_average) }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                              <td class="text-center" style="width: 10%">
                                <div v-for="(details, a) in data" :key="a">
                                  <template v-if="a === item.key">
                                    <div v-for="(sum, tt) in details" :key="tt">
                                      <div v-for="(e, f) in sum" :key="f">
                                        <template v-if="level === f">
                                          {{
                                            parseFloat(
                                              e.cumulative_weighted_average
                                            )
                                          }}
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                    </template>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-overlay :absolute="absolute" :value="overlay" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import {
    defineComponent,
    getCurrentInstance,
    provide,
    reactive,
    ref,
    toRefs,
  } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";

  export default defineComponent({
    components: { PageHeader, Response },
    setup() {
      const vm = getCurrentInstance();
      const { getTranscript, getSemester, signOut } = useActions([
        "getTranscript",
        "getSemester",
        "signOut",
      ]);
      const levelcourseBasket = ref([]);
      let absolute = ref(true);
      let overlay = ref(false);

      const {
        user,
        getters_transcript,
        getters_level,
        getters_semester,
        getters_user_grade_system,
      } = useGetters([
        "user",
        "getters_transcript",
        "getters_level",
        "getters_semester",
        "getters_user_grade_system",
      ]);
      let data = ref([]);

      const { getStudentCampus, getLevel } = useActions([
        "getStudentCampus",
        "getLevel",
      ]);

      const { getters_student_campus } = useGetters(["getters_student_campus"]);

      const searchStudentHeader = reactive({
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        color: null,
        response: false,
        showClassExamScore: false,
      });

      const {
        response,
        color,
        searchStudent,
        studentcampusBasket,
        loadStudent,
        msgHeader,
        msgBody,
        msgIcon,
        actionResponse,
      } = toRefs(searchStudentHeader);

      provide("color", color);

      const getstudentResult = (index_number) => {
        actionResponse.value = false;

        overlay.value = true;

        Promise.all([
          getTranscript(index_number)
            .finally(() => {
              levelcourseBasket.value = [];
              overlay.value = false;
              response.value = true;
              if (getters_transcript.value.length > 0) {
                levelcourseBasket.value = getters_transcript.value;
                let hours = [...levelcourseBasket.value];

                let x = [];
                let q = [];
                let t = [];
                let u = [];

                data.value = hours.reduce((sum, obj) => {
                  for (const semester in obj.acc) {
                    x = obj.acc[semester].reduce((a, b) => {
                      a[semester] = a[semester] || {
                        credit: 0,
                        cumulative_credit: 0,
                        total: 0,
                        cumulative_total: 0,
                        credit_obtained: 0,
                        cumulative_credit_obtained: 0,
                        weighted_average: 0,
                        cumulative_weighted_average: 0,
                      };
                      a[semester].credit += b.credit_hours;
                      if (b.grade_point !== null)
                        a[semester].total += b.grade_point * b.credit_hours;
                      else a[semester].total += b.total_score * b.credit_hours;
                      if (b.passed) {
                        a[semester].credit_obtained += b.credit_hours;
                        t.push(b.credit_hours);
                        a[semester].cumulative_credit_obtained = t.reduce(
                          (x, y) => x + y,
                          0
                        );
                      }
                      a[semester].weighted_average = parseFloat(
                        a[semester].total / a[semester].credit
                      ).toFixed(2);

                      q.push(b.credit_hours);
                      a[semester].cumulative_credit = q.reduce(
                        (x, y) => x + y,
                        0
                      );

                      if (b.grade_point !== null)
                        u.push(b.grade_point * b.credit_hours);
                      else u.push(b.total_score * b.credit_hours);

                      a[semester].cumulative_total = u.reduce((e, r) => e + r, 0);
                      a[semester].cumulative_weighted_average = parseFloat(
                        a[semester].cumulative_total /
                          a[semester].cumulative_credit
                      ).toFixed(2);
                      return a;
                    }, {});

                    if (!sum[obj.key]) {
                      sum[obj.key] = [];
                    }

                    sum[obj.key].push(x);
                  }

                  return sum;
                }, {});
              }
            })
            .catch((e) => {
              console.log(e);
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              actionResponse.value = true;
              if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              }
            }),
        ]);
      };

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          actionResponse.value = false;
          if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          const infoBasket = {
            role: user.value.role,
            campus: user.value.campus,
          };
          getStudentCampus(infoBasket)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            })
            .catch((e) => {
              actionResponse.value = true;
              if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              }
            });
        }
      });

      Promise.all([getLevel(), getSemester()]);

      return {
        ...toRefs(searchStudentHeader),
        debouncesearchStudent,
        levelcourseBasket,
        getters_level,
        getters_semester,
        data,
        absolute,
        overlay,
        getstudentResult,
        getters_transcript,
        getters_user_grade_system,
      };
    },
  });
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
</style>